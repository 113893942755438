/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
       //Side Menu
        var isLateralNavAnimating = false;
        //open/close lateral navigation
        $('.hamburger-menu').on('click', function(event) {
          event.preventDefault();
          //stop if nav animation is running 
          if (!isLateralNavAnimating) {
            if ($(this).parents('.csstransitions').length > 0) isLateralNavAnimating = true;
            $('body').toggleClass('navigation-is-open');
            $(this).toggleClass('active');
            if ($('body').hasClass("navigation-is-open")) {
              //$(this).text("CLOSE");
            } else {
              //$(this).text("MENU");
            }
            $('.main-nav').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function() {
              //animation is over
              isLateralNavAnimating = false;
            });
          }
        });

        $(".scroll_down").click(function() {
          
          console.log("hello");
          
            $('html, body').animate({
                scrollTop: $("#gallery_section").offset().top
            }, 800);
        });
        
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.slider').slick({
          draggable: true,
          arrows: true,
          dots: true,
          fade: true,
          autoplay: true,
          autoplaySpeed: 5000,
          speed: 900,
          infinite: true,
          cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)'
			  });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'page_template_template_about': {
      init: function() {
        
        $('#gallery_grid').lightGallery({
          thumbnail:true,
          animateThumb: false,
          showThumbByDefault: false,
          selector: '.item'
          }); 
        
        
        //slider 
        $('.single_slider').slick({
        dots: true,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 5000,
      });
        
        
      }
    },
     'history': {
          init: function() {
      //slider 
        $('.single_slider').slick({
         dots: true,
          infinite: true,
          speed: 500,
          fade: true,
          cssEase: 'linear',
          autoplay: true,
          autoplaySpeed: 5000,
			  });
       
     }
     }
    
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
